import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sucess',
  templateUrl: './sucess.component.html',
  styleUrls: ['./sucess.component.scss']
})
export class SucessComponent implements OnInit {
  yourVariable:string = "sim";
  constructor() { }

  ngOnInit() {
  }
  fechar(){
    this.yourVariable='nao';
  }

}
