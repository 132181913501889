import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'LTJ';
  isOnS=false;
  isOnF=false;
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      let date = params['startdate'];
      console.log(date); // Print the parameter to the console. 
    });
    console.log(this.router.url);
    if (this.router.url == "/sucess") {
      this.isOnS=true;
    }
    if (this.router.url == "/error") {
      this.isOnF=true;
    }


  }

}
