import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxMaskModule, IConfig} from 'ngx-mask';


import { BrowserModule } from '@angular/platform-browser';
import { NgModule, asNativeElements } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { QuemSomosComponent } from './quem-somos/quem-somos.component';
import { ObrasComponent } from './obras/obras.component';
import { ComoFuncionaComponent } from './como-funciona/como-funciona.component';
import { ContatoComponent } from './contato/contato.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ErrorComponent } from './error/error.component';
import { SucessComponent } from './sucess/sucess.component';
import { ConstrucaoComponent } from './construcao/construcao.component';
import { ReabilitacaoComponent } from './reabilitacao/reabilitacao.component';
import { SistemaLfsComponent } from './sistema-lfs/sistema-lfs.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    QuemSomosComponent,
    ObrasComponent,
    ComoFuncionaComponent,
    ContatoComponent,
    FooterComponent,
    HeaderComponent,
    ErrorComponent,
    SucessComponent,
    ConstrucaoComponent,
    ReabilitacaoComponent,
    SistemaLfsComponent
  ],
  imports: [
    NgxMaskModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbCollapseModule
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
