import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ConstrucaoComponent } from './construcao/construcao.component';

const routes: Routes = [
  {path:'',component:HomeComponent},
  {path:'sucess',component:HomeComponent},
  {path:'error',component:HomeComponent}

 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
