import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { DOCUMENT } from '@angular/common';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  windowScrolled: boolean;
  sticky: boolean = false;
  stickyRemove: boolean = false;
  @ViewChildren('stickyMenu') menuElement: ElementRef;
  menuPosition: any;
  constructor(@Inject(DOCUMENT) private document: Document) {

  }
  ngAfterViewInit() {
  
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
      this.sticky = true;
      this.stickyRemove= false;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
      this.sticky = false;
      this.stickyRemove= true;
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }
  

  ngOnInit() {



  }
  scrollTo(className: string): void {
    const elementList = document.querySelectorAll('.' + className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }


}

