import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isOnS=false;
  isOnF=false;
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      let date = params['startdate'];
      console.log(date); // Print the parameter to the console. 
    });
    console.log(this.router.url);
    if (this.router.url == "/sucess") {
      this.isOnS=true;
    }
    if (this.router.url == "/error") {
      this.isOnF=true;
    }
    if(this.router.url !='/' ){
      setTimeout(() => {
        history.pushState('', 'LTJ', '/');
      }, 10500);
    }
  }

  ngOnInit() {
    
  }

}
