import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-obras',
  templateUrl: './obras.component.html',
  styleUrls: ['./obras.component.scss']
})
export class ObrasComponent implements OnInit {
  imagem:string;
  pageFoto1:boolean=true;
  pageVideo1:boolean=true;
  pageFoto2:boolean;
  pageVideo2:boolean;
  pageFoto3:boolean;
  pageVideo4:boolean;
  contador:number=0;
  constructor() { }

  ngOnInit() {
  }
  mudarImagem(parametro){
    this.imagem=parametro;
  }
  trocaConteudo(parametro){
    if(parametro=="1"){
      if(this.contador<1){
      this.contador++;
      }
    }
    if(parametro=="2"){
      if(this.contador>0){
      this.contador--;
      }
    }
    if(this.contador==1){
      this.pageFoto1=false;
      this.pageFoto2=true
    }
    if(this.contador==0){
      this.pageFoto1=true;
      this.pageFoto2=false;
    }

  }
  trocaConteudoVideo(parametro){
    if(parametro=="1"){
      if(this.contador<1){
      this.contador++;
      }
    }
    if(parametro=="2"){
      if(this.contador>0){
      this.contador--;
      }
    }
    if(this.contador==1){
      this.pageVideo1=false;
      this.pageVideo2=true
    }
    if(this.contador==0){
      this.pageVideo1=true;
      this.pageVideo2=false;
    }

  }


}
