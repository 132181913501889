import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sistema-lfs',
  templateUrl: './sistema-lfs.component.html',
  styleUrls: ['./sistema-lfs.component.scss']
})
export class SistemaLfsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
